<template>
  <div class="container pb-5">
    <div class="row">
      <div class="col-8 offset-2">
        <h5 class="font-weight-light">
          <b>
            Dados bancários
          </b>
        </h5>
        <hr class="mt-3 mb-3" />
        <div class="alert alert-info">
          <p>
            Precisamos dos seus dados bancários, para nosso financeiro efetuar o
            pagamento via pix da comissão dos seguros vendidos, aprovados e
            pagos pelos clientes.
          </p>
          <p>
            <b> ATENÇÃO: </b> seus dados bancários, devem estar corretamente
            informados para evitar problemas de pagamento. caso tenha dúvidas
            favor enviar um email para
            <a href="mailto:samarone@bikeregistrada.com.br"
              >samarone@bikeregistrada.com.br</a
            >
          </p>
        </div>

        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(salvar)">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Tipo da chave PIX</label>
                  <ValidationProvider
                    name="tipo da chave pix"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <select
                      class="form-control"
                      v-model="dados.tipoChave"
                      :class="classes"
                      @change="limpar"
                    >
                      <option :value="null" selected>Selecione...</option>
                      <option value="CPF">CPF</option>
                      <option value="CNPJ">CNPJ</option>
                      <option value="EMAIL">Email</option>
                      <option value="TELEFONE">Telefone</option>
                      <option value="CHAVE-ALEATORIA">Chave Aleatória</option>
                    </select>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div v-show="dados.tipoChave" class="form-group">
                  <label>Chave Pix</label>
                  <ValidationProvider
                    v-if="dados.tipoChave == 'CPF'"
                    name="chave pix CPF"
                    rules="required|cpf"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.cpf"
                      :class="classes"
                      placeholder="Chave pix CPF"
                      v-mask="'###.###.###-##'"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="dados.tipoChave == 'CNPJ'"
                    name="chave pix cnpj"
                    rules="required|cnpj"
                    v-slot="{ classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.cnpj"
                      :class="classes"
                      v-mask="'##.###.###/####-##'"
                      disabled
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="dados.tipoChave == 'EMAIL'"
                    name="email"
                    rules="required|email"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.email"
                      placeholder="Chave pix email"
                      :class="classes"
                      v-mask="dados.email"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="dados.tipoChave == 'TELEFONE'"
                    name="telefone"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      v-model="dados.telefone"
                      :class="classes"
                      placeholder="Chave pix Telefone"
                      v-mask="'(##) #####-####'"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="dados.tipoChave == 'CHAVE-ALEATORIA'"
                    name="chave pix aleatória"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.aleatoria"
                      :class="classes"
                      placeholder="Chave pix aleatória"
                      v-mask="dados.cpf"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Agência</label>
                  <ValidationProvider
                    name="agência"
                    rules="required|min:3"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.agencia"
                      :class="classes"
                      placeholder="Agência"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Conta</label>
                  <ValidationProvider
                    name="conta"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.conta"
                      :class="classes"
                      placeholder="Conta"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Nome do titular</label>
                  <ValidationProvider
                    name="nome do titular"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.nomeTitular"
                      :class="classes"
                      placeholder="Nome titular"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <hr class="mt-3 mb-3" />
            <button
              class="btn btn-success btn-block"
              type="submit"
              :disabled="loading || invalid"
            >
              <span v-if="loading">Salvando dados bancarios...</span>
              <span v-else>Salvar dados bancários</span>
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import auth from "../../auth";
import captureError from "../../helpers/captureError";

export default {
  components: {},
  data() {
    return {
      config,
      dados: {
        cpf: undefined,
        cnpj: undefined,
        email: undefined,
        telefone: undefined,
        agencia: undefined,
        conta: undefined,
        nomeTitular: undefined,
        tipoChave: undefined,
        aleatoria: undefined,
      },
      loading: false,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (!userInfo.cnpj) return this.$router.replace("/parceiro");

    this.dados.cnpj = userInfo.cnpj;

    this.dados.idLojista = userInfo.id;

    this.buscarDadosBancarios();
  },

  methods: {
    async buscarDadosBancarios() {
      try {
        var response = await axios.get(
          `${config.API_URLV2}/Lojista/${this.dados.idLojista}`
        );

        var dados = response.data;

        this.dados.cpf = dados.cpfPIX;
        this.dados.email = dados.emailPIX;
        this.dados.telefone = dados.telefonePIX;
        this.dados.agencia = dados.agenciaBanco;
        this.dados.conta = dados.contaBanco;
        this.dados.nomeTitular = dados.nomeTitularBanco;
        this.dados.tipoChave = dados.tipoChavePIX;
        this.dados.aleatoria = dados.chaveAleatoriaPIX;

      } catch (error) {
        this.$toast.error("Não foi possivel buscar os dados bancarios");
      }
    },
    limpar() {
      this.dados.cpf = undefined;
      this.dados.email = undefined;
      this.dados.telefone = undefined;
      this.dados.aleatoria = undefined;
    },
    async salvar() {
      try {
        this.loading = true;

        var data = JSON.parse(JSON.stringify(this.dados));

        if (data.tipoChave != "CNPJ") data.cnpj = null;

        await axios.post(`${config.API_URLV2}/Lojista/DadosBancarios`, data);

        this.$toast.success("Dados bancarios salvos");
      } catch (e) {
        captureError(e);
        Swal.fire({
          text: e.response.data.Message
            ? e.response.data.Message
            : "Não foi possivel salvar os dados bancários",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.form-group label {
  font-weight: bold;
  font-size: 0.9rem;
}
</style>
